const experienceData = [
  {
    company: 'Salesforce',
    role: 'Software Engineer',
    dates: 'June 2019 - September 2019', // Replace with your actual dates
    description: 'Cloud Technology',
  },
  {
    company: 'Sinequa',
    role: 'Solutions Engineer',
    dates: 'January 2019 - February 2019', // Replace with your actual dates
    description: 'Natural Language Processing Tools',
  },
];

export default experienceData;
